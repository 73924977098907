<template>
  <v-card flat class="pt-12">

      <row-titulo-seccion 
        titulo-seccion="NOSOTROS"
        titulo-descripcion ='
          TOKENFIT INNOVA CON LA PRIMERA DApp PARA LA VENTA DE UN
          INMUEBLE 100% MEDIANTE TECNOLOGÍA BLOCKCHAIN EN LA ARGENTINA' />

      <accordion-nosotros />

      <equipo />

      <cerebros />

  </v-card>
</template>

<script>
export default {
  name: 'Nosotros',
  components: {
    //InscribiteButton: () => import('@/views/components/InscribiteButton'),
    RowTituloSeccion: () => import('@/views/components/RowTituloSeccion'),
    AccordionNosotros: () => import('@/views/Nosotros/AccordionNosotros'),
    Equipo: () => import('@/views/Nosotros/Equipo'),
    Cerebros: () => import('@/views/Nosotros/Cerebros')
  },
  data() {
    return {
      height: '300px'
    }
  } 
}
</script>

<style>

</style>