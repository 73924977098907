<template>
  <v-card flat class="pt-10">
    <v-container fluid class="pl-0 pr-0 pb-0">

      <row-titulo-seccion 
        titulo-seccion="CONTACTO"
        titulo-descripcion='PARTICIPÁ DE LA PRIMERA VENTA DE UN INMUEBLE<br><span class="turquesa--text">100% MEDIANTE TECNOLOGÍA BLOCKCHAIN</span>' />

      
      <v-row no-gutters class="body2">
        <v-container class="pa-0">
          <v-row no-gutters style="height: 80px">
            <v-col class="mt-0 pt-0 mb-0 pb-0">
              <v-img eager class="d-inline-flex" src="assets/tokenfit-bg-step-00-repeat-y.png" height="80px" position="center bottom" width="13px" style="margin-left: 5px"/>
            </v-col>
          </v-row>
          <v-row no-gutters>

            <!-- Seccion azul -->
            <v-col cols="12" lg="7">
              <v-card flat color="azul2" class="rounded-0 pt-6" ref="formCard">
                <v-container fluid fill-height class="pb-10">
                  <v-form v-model="formValid" ref="contactoForm" lazy-validation>
                    <v-row class="pl-6 pr-6 form-first-padding">
                      <v-col cols="12" class="text-left">
                        GRACIAS POR COMPLETAR...
                      </v-col>

                      <template v-for="(field, i) in form">
                        <v-col :cols="field.cols" :lg="field.lg" :key="`field-${i}`" class="pa-0" >

                          <v-text-field v-if="field.type == 'text'"
                            :class="i==0 && !$vuetify.breakpoint.mobile ? 'pr-5' : ''"
                            light
                            dense
                            solo
                            required
                            color="body"
                            background-color="white"
                            v-model="formData[field.model]"
                            :rules="field.rules"
                            :label="field.label"
                            outlined />

                          <v-textarea v-if="field.type == 'textarea'"
                            light
                            dense
                            solo
                            required
                            background-color="white"
                            v-model="formData[field.model]"
                            :rules="field.rules"
                            :label="field.label"
                            outlined />

                          <v-checkbox v-if="field.type == 'checkbox'"
                            hide-details
                            v-model="formData[field.model]"
                            :label="field.label" />

                        </v-col>
                      </template>

                      <v-col cols="12" class="text-right">
                        <vue-recaptcha class="d-flex justify-end" @verify="validateReCaptcha" :sitekey="reCaptchaKey" />
                      </v-col>                      
                      <v-col cols="12" class="text-right">
                        <enviar-button @click="sendContactMail" :enabled="formValid && !isFormBlank && captchaVerify"/>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card>
            </v-col>

            <!-- seccion blanca -->
            <v-col cols="12" lg="3" offset-lg="1"
              :class="
              [
                'align-self-center',
              ]">
              <v-container fluid fill-height align-start>
                <v-row class="pt-5 pb-5">
                  <v-col 
                    cols="12" 
                    :class="
                    [
                      $vuetify.breakpoint.mobile ? '' : 'text-left',
                      'white--text'
                    ]">
                    TAMBIÉN PODES SEGUIRNOS EN NUESTRAS <span class="turquesa--text">REDES SOCIALES</span>
                  </v-col>
                  <v-col 
                    v-for="(link, i) in rrss" :key="`contactorrss-${i}`"
                    cols="12"
                    :class="
                    [
                      $vuetify.breakpoint.mobile ? 'justify-center' : 'text-left',
                      'd-inline-flex align-center'
                    ]">
                    <v-img :src="link.img" max-width="40px" class=""/>
                    <a target="_blank" class="white--text ml-5" :href="link.link">{{ link.label }}</a>
                  </v-col>
                  <v-col
                    cols="12"
                    :class="
                    [
                      $vuetify.breakpoint.mobile ? 'justify-center' : 'text-left',
                      'd-inline-flex align-center'
                    ]">
                     <a href="mailto:contacto@tokenfit.io" class="turquesa--text">contacto@tokenfit.io</a>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row no-gutters style="height: 80px" v-if="!$vuetify.breakpoint.mobile">
            <v-col class="mt-0 pt-0 mb-0 pb-0">
              <v-img eager class="d-inline-flex" src="assets/tokenfit-bg-step-00-repeat-y.png" height="80px" width="13px" style="margin-left: 5px"/>
            </v-col>
          </v-row>          
        </v-container>
      </v-row>
    </v-container>

    <texto-contacto />
  </v-card>
</template>

<script>
import * as emailService from '../../services/emailService';
import VueRecaptcha from 'vue-recaptcha';
const reCaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY;
import EventBus from '@/utils/eventbus'
import { requiredRules, emailRules } from '../../validateRules'
export default {
  name: 'Contacto',
  components: {
    RowTituloSeccion: () => import('@/views/components/RowTituloSeccion'),
    EnviarButton: () => import('@/views/components/EnviarButton'),
    TextoContacto: () => import('@/views/Contacto/TextoContacto'),
    VueRecaptcha
  },
  props: {
    rrss: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      formValid: false,
      formData: {
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        mensaje: '',
        //politica: false
      },
      form: [
        {lg: 6, cols: 12, model: 'nombre', type: 'text', label: 'Nombre', rules: requiredRules},
        {lg: 6, cols: 12, model: 'apellido', type: 'text', label: 'Apellido', rules: requiredRules},
        {lg: 12, cols: 12, model: 'email', type: 'text', label: 'Email', rules: emailRules},
        {lg: 12, cols: 12, model: 'telefono', type: 'text', label: 'Teléfono', rules: requiredRules},
        {lg: 12, cols: 12, model: 'mensaje', type: 'textarea', label: 'Mensaje', rules: requiredRules},
        //{lg: 12, cols: 12, model: 'politica', type: 'checkbox', label: 'Acepto la política de...'},
      ],
      captchaVerify: false,
      reCaptchaKey
    }
  },
  computed: {
    isFormBlank() {
      return this.formData.nombre.length <= 0 ||
        this.formData.apellido.length <= 0 ||
        this.formData.email.length <= 0 ||
        this.formData.telefono.length <= 0 ||
        this.formData.mensaje.length <= 0
    }
  },
  methods: {
    sendContactMail() {
      emailService.sendMail(this.formData)
        .then(resp => {
          if (resp.data.status == 'OK') {
            EventBus.$emit('show-snackbar', {msg: 'ENVIADO CORRECTAMENTE', color: 'success'})
            this.clearForm()
          } else {
            EventBus.$emit('show-snackbar', {msg: `OCURRIO UN ERROR`, color: 'red accent-2'})
          }
        })
        .catch(err => {
          EventBus.$emit('show-snackbar', {msg: `OCURRIO UN ERROR: ${err.message}`, color: 'red accent-2'})
        })

      EventBus.$emit('show-snackbar', {msg: 'ENVIANDO, AGUARDE POR FAVOR...', color: 'teal'})
    },
    clearForm() {
      this.formData = {
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        mensaje: '',
        //politica: false
      }
    },
    validateReCaptcha() {
      this.captchaVerify = true
    }
  },
  mounted() {
    this.clearForm()
  }
}
</script>

<style>
</style>