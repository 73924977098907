<template>
  <v-card flat class="pt-12">

    <row-titulo-seccion 
      titulo-seccion="NFT COLECCIONABLE"
      titulo-descripcion='TÍTULO PRINCIPAL DE LA PÁGINA (H1) <span class="turquesa--text">CON COLOR</span>' />

    <!-- this section don't apply with BaseSection, we cannot use base component -->
    <v-expansion-panels accordion flat>
      <v-expansion-panel>
        <v-card class="card-bg-step-04">
          <v-container class="pa-0 text-left">
            <v-row no-gutters>

              <v-col cols="12" sm="12" md="12" lg="6" class="pt-10">
                <h2 class="d-block pa-3">{{publicacion.titulo}}</h2>
                <v-img contain :src="publicacion.imagen" />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="2">
                <img @load="bgLoaded = true" v-if="$vuetify.breakpoint.mobile" :src="`assets/tokenfit-bg-step-01-mobile.png`" style="width: 100%"/> <!-- se usa 102% para que cubra todo el bottom -->
                <img @load="bgLoaded = true" v-if="!$vuetify.breakpoint.mobile" :src="`assets/tokenfit-bg-nft-step-01.png`" style="height: 100%; margin-left: -4px;"/>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="4" class="align-self-center">
                <v-container>

                  <v-row>
                    <v-col cols="12" class="pb-10">
                      <h3 class="violeta--text">INFORMACIONES (H3)</h3>
                    </v-col>
                  </v-row>
                
                  <v-row v-for="(item, i) in publicacion.items" :key="`desc${i}`">
                    <v-col cols="2" class="pt-0 pb-0"><img :src="item.icon" width="24px" height="24px"></v-col>
                    <v-col cols="7" class="pt-0 pb-0 text-left"><span class="chivo white--text">{{item.label}}</span></v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="pt-10">
                      <span class="chivo">{{publicacion.detalle}}</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="8" class="text-left pa-0">
                      <accordion-detalles-button 
                        :content="publicacion.detalle" 
                        :intersecting="true"/>
                    </v-col>
                  </v-row>

                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-expansion-panel-content flat class="ma-0">
        <expansion-content
          class="ma-0"
          step="04"
          :description="publicacion.moreDataDescription"
          more-data-with-saber-mas-button />
      </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
     <!------------------------------------------------------------------------->

     <texto-n-f-t />
  </v-card>
</template>


<script>
export default {
  name: 'NFT',
  components: {
    AccordionDetallesButton: () => import('@/views/components/AccordionDetallesButton'),
    RowTituloSeccion: () => import('@/views/components/RowTituloSeccion'),
    ExpansionContent: () => import('@/views/components/ExpansionContent'),
    TextoNFT: () => import('@/views/NFT/TextoNFT')
  },
  data() {
    return {
      publicacion: {
        titulo: 'TÍTULO DE LA PUBLICACIÓN (H2)',
        imagen: 'assets/NFT-01-sample.jpg',
        items: [
          {icon: 'assets/ic-nft-room.svg', label: '7 ambientes'},
          {icon: 'assets/ic-nft-bedroom.svg', label: '3 dormitorios'},
          {icon: 'assets/ic-nft-bathroom.svg', label: '2 baños'},
          {icon: 'assets/ic-nft-square-meter.svg', label: '112 mts2'},
        ],
        detalle: 'Garage, parrilla y otras info...',
        moreDataDescription: `
        <span class="azul4--text font-weight-bold">Subtítulo (h4)</span>
        <p></p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet ornare mi et pellentesque. Nullam sed vestibulum nibh ut lacus sit amet</p>
        <p>Curabitur eget metus varius, pretium dolor ut, vestibulum enim. Nam ut lacus sit amet nulla, consectetur adipiscing elit venenatis aliquet. Mauris odio augue.</p>`
      }
    }
  }
}
</script>

<style>

</style>